<template>
  <div>
    <div class="edit--collection--form">
      <form class="edit--collection-formItem" v-on:submit.prevent="checkForm">
        <div class="form--wrapper">
          <div class="form--input--error" v-if="errors.errorMsg.length">
            <ul>
              <li v-for="(error, index) in errors.errorMsg" :key="index">
                {{ error }}
              </li>
            </ul>
          </div>
          <h2>
            {{ fieldLabel }}
          </h2>
          <div
            class="form--input--wrapper"
            v-if="fieldType == 'title_en' || fieldType == 'title_tc'"
          >
            <input
              type="text"
              class="input"
              :placeholder="fieldLabel"
              v-model="changeValue"
            />
          </div>
          <div
            class="form--input--wrapper"
            v-if="
              fieldType == 'description_en' || fieldType == 'description_tc'
            "
          >
            <textarea
              v-model="changeValue"
              :placeholder="fieldLabel"
            ></textarea>
          </div>
        </div>
        <button type="submit" class="edit--submit btn--blk">
          {{ $t("collection.collection_update_button") }}
        </button>
      </form>
    </div>
  </div>
</template>
<script>
import { authHeader } from "@/helpers/authHeader";

export default {
  name: "editCollectionForm",
  data() {
    return {
      errors: {
        titleEn: false,
        titleTc: false,
        errorMsg: []
      },
      collectionForm: {
        title_en: "",
        title_tc: "",
        description_en: "",
        description_tc: ""
      },
      changeValue: this.OrigValue
    };
  },

  props: {
    recordId: Number,
    customFun: Function,
    profileNewCollection: Boolean,
    fieldType: String,
    fieldLabel: String,
    OrigValue: String
  },
  watch: {
    OrigValue(newVal) {
      this.changeValue = newVal;
    }
  },
  methods: {
    checkForm: function(e) {
      this.collectionForm[this.fieldType] = this.changeValue;
      //   if (this.collectionForm.title_en && this.collectionForm.title_tc) {
      this.apiUpdateTheCollection(this.collectionForm);
      //   }

      //   this.errors.titleEn = false;
      //   this.errors.titleTc = false;
      //   this.errors.errorMsg = [];

      //   if (!this.collectionForm.title_en) {
      //     this.errors.titleEn = true;
      //     this.errors.errorMsg.push("English name required.");
      //   }
      //   if (!this.collectionForm.title_tc) {
      //     this.errors.titleTc = true;
      //     this.errors.errorMsg.push("Chinese name required.");
      //   }
    },

    async apiUpdateTheCollection(formData) {
      try {
        const collectionID = this.$route.params.id;
        const response = await fetch(
          `${process.env.VUE_APP_BASE_API_URL}/collection/${collectionID}`,
          {
            method: "PATCH",
            mode: "cors",
            cache: "no-cache",
            headers: { "Content-Type": "application/json", ...authHeader() },
            body: JSON.stringify(formData)
          }
        );
        if (!response.ok) {
          throw new Error("API Error");
        }

        let updateDCollection = await response.json();

        if (response.status == 200) {
          this.$parent.collectionClosed();
        }
        // if (!this.profileNewCollection) {
        //   this.customFun(this.recordId, createdCollection.id);
        // } else {
        //   this.$parent.collectionClosed();
        // }
        // this.collectionForm.title_en = "";
        // this.collectionForm.title_tc = "";
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.close-btn {
  z-index: 100;
  top: -40px;
  right: 0;
}
.edit--collection--form {
  height: 100%;
}
.form--wrapper {
  padding: 50px 30px;

  h2 {
    font-size: 24px;
    font-weight: 200;
    margin-bottom: 34px;
  }
  label {
    font-size: 18px;
  }
  input[type="text"] {
    display: block;
    width: 100%;
    padding-bottom: 5px;
    margin: 8px 0;
    box-sizing: border-box;
    font-size: 22px;
    border-bottom: 1px solid #555;
    outline: none;
    &::placeholder {
      // color:
      font-size: 18px;
      @include respond-to(screen-md) {
        font-size: 22px;
      }
    }
    &.error {
      border-bottom-color: red;
    }
  }
}
.form--input {
  &--wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    @include respond-to(screen-md) {
      margin-bottom: 20px;
    }
    label {
      width: 10%;
    }

    textarea {
      width: 100%;
      font-size: 16px;
      resize: none;
      border: 1px solid #000;
      background: #e6e6e6;
      height: 150px;
    }

    .private--collection {
      position: relative;
      margin-top: 20px;
      .c-cb-wrap {
        // opacity: .4;
        line-height: 1;
        width: 100%;
        display: block;
        position: relative;
        padding-left: 20px;
        margin-bottom: 0px;
        cursor: pointer;
        font-size: 18px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: flex;
        align-items: center;
        & input {
          opacity: 0.4;
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
          &:checked ~ .checkmark {
            background-color: #e6e6e6;
            border: 1px solid black;
          }
          &:checked ~ .checkmark:after {
            background-color: #e6e6e6;
            border: 1px solid black;
            // opacity: 0;
          }
          &:checked {
            opacity: 1;
          }
        }
      }
      .checkmark {
        top: 50%;
        /* margin: auto; */
        transform: translateY(-50%);
        position: absolute;
        // top: 0;
        left: 0;
        height: 13px;
        width: 13px;
        background-color: rgb(0, 0, 0);
        border: 1px solid black;
        border-radius: 50%;
        &:after {
          content: "";
          position: absolute;
          display: none;
          // position: absolute;
          // left: 9px;
          // top: 5px;
          // width: 5px;
          // height: 10px;
          // border: solid white;
          // border-width: 0 3px 3px 0;
          // -webkit-transform: rotate(45deg);
          // -ms-transform: rotate(45deg);
          // transform: rotate(45deg);
        }
      }
    }
  }
  &--error {
    margin-bottom: 20px;
    ul {
      color: red;
      margin: 0;
      padding: 0;
    }
  }
}
.c-cb {
  justify-content: left;
}
.edit--submit {
  width: 100%;
  text-transform: uppercase;
}

.edit--collection-formItem {
  width: 100%;
  height: 400px;
  position: relative;
  display: flex;
  flex-flow: column;
  @include respond-to(screen-md) {
    width: 500px;
  }
  > div {
    position: relative;
    /* margin-bottom: auto; */
    margin: auto;
    width: 100%;
    .edit--submit {
      margin-top: auto;
    }
  }
}
</style>
